import mhHead from "../../../../../static/images/icons/mh_head.png";
import mhTelescope from "../../../../../static/images/icons/mh_telescope.png";

export default [
	{
		name: "Wellness",
		details:
			"Book a teleconsult with licensed Wellness professionals for mental health and well-being concerns.",
		img: mhHead,
		url: "/services",
		value: Buffer.from(process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
	},
	{
		name: "Physical Health",
		details:
			"Book a teleconsult with licensed doctors for a general check up, prescription renewal, or return to office clearance.",
		img: mhTelescope,
		url: `/booking/body/intake`,
		value: Buffer.from(process.env.GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE),
	},
];
